import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  PDFDownloadLink,
  Font,
  Link,
} from "@react-pdf/renderer";
import logo from "../../../assets/pdfLogo.jpg";
import { header, footer, watermark } from "../../../assets/index.js";
import {
  MyriadProSemibold,
  MyriadProRegular,
  MyriadProBold,
} from "../../../assets/index.js";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import moment from "moment";
import {
  capitalizeFirstLetter,
  removeLeadingComma,
} from "../../../utilities/utilities";

const HeaderFooter = ({ header, footer }) => (
  <>
    <View style={styles.header} fixed>
      <View>
        <Image style={styles.headerImage} src={header} />
        <View style={styles.docHeader}>
          <Text style={styles.name}>
            <Text style={{ fontWeight: "bold" }}>Dr. Satish</Text> Pandey
          </Text>
          <Text style={styles.role}>Pediatrician</Text>
          <Text style={styles.qualifications}>
            MBBS, M.D (Pediatrics), D.O (India)
          </Text>
          <Text style={styles.center}>
            New Horizon Child Development Centre
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.footer} fixed>
      <Image style={styles.footerImage} src={footer} />
    </View>
  </>
);

const Watermark = ({ watermark }) => (
  <View style={styles.watermarkContainer} fixed>
    <Image style={styles.watermarkImage} src={watermark} />
  </View>
);

const PDFDocument = ({ children }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Watermark watermark={watermark} />
      <HeaderFooter header={header} footer={footer} />
      <View style={styles.content}>{children}</View>
    </Page>
  </Document>
);

const ReportPdf = () => {
  const navigate = useNavigate();
  const { id: reportId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const fetchReport = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/form/form-builder/reporting/${reportId}`
      );
      const data = response.data.data;
      const demographic = data?.form_data?.find((item) =>
        item.form_group_name.includes("Demographic")
      );
      const checkCases = ["First name", "Middle Name", "Gender", "Age"];
      let check = 0;
      checkCases.forEach((item) => {
        if (
          demographic &&
          demographic.form_field_data.find(
            (field) =>
              field.form_field_name.trim().toLocaleLowerCase() ===
                item.trim().toLocaleLowerCase() &&
              field.form_field_value !== null
          )
        ) {
          check++;
        }
      });
      if (check !== checkCases.length) {
        setData(data);
      } else {
        setData(data);
      }
      // setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchReport();
  }, [reportId]);

  if (loading) return <FallingLinesLoader />;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto border-b pb-5">
          <h1 className="text-lg font-medium leading-6 text-gray-900">
            {" "}
            {reportId ? "Report" : "Report Not Found"}
          </h1>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>
      </div>
      <div className=" flex-1 items-center justify-center h-max ">
        {loading === true ? (
          <FallingLinesLoader />
        ) : (
          <div className="">
            <PDFViewer height={1000} width={800} showToolbar={false}>
              <PdfFile res={data} />
            </PDFViewer>
          </div>
        )}
      </div>
      <div className="flex-1 flex items-center justify-center w-full h-max">
        <button className="inline-flex items-center rounded-md border mt-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <PDFDownloadLink
            document={<PdfFile res={data} />}
            fileName="report.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Generateing..." : "Print"
            }
          </PDFDownloadLink>
        </button>
      </div>
    </div>
  );
};

const PdfFile = ({ res }) => {
  const PDetails = res.patient;
  const updatedFormData = res.form_data.map((formGroup, index) => {
    const filteredFormFields = formGroup.form_field_data.filter(
      (formField, i) =>
        !(
          formField.form_field_type === "heading" &&
          formGroup.form_field_data[i + 1]?.form_field_value === null
        ) &&
        !(
          formField.form_field_type !== "heading" &&
          formField.form_field_value === null
        )
    );

    return {
      ...formGroup,
      form_field_data: filteredFormFields,
    };
  });
  console.log("🚀 ~ updatedFormData ~ updatedFormData:", updatedFormData);

  function convertToArr(string) {
    const goldenRules = [...new Set(string.split(","))].filter(
      (elem) => elem.length > 1
    );
    return goldenRules;
  }

  return (
    <PDFDocument>
      <View
        style={[
          styles.contentHeader,
          { display: "flex", flexDirection: "row" },
        ]}
      >
        <View style={{ width: "70%", marginLeft: -20 }}>
          <View style={[{ flexDirection: "row", paddingLeft: 20 }]}>
            <Text style={{ fontWeight: "bold" }}>DATE : </Text>
            <Text>
              {res?.date
                ? moment(res?.date).utcOffset(0).format("DD/MM/YYYY")
                : "-"}
            </Text>
          </View>

          {updatedFormData?.map(
            (item, index) =>
              item.form_group_name === "Demographic Details" && (
                <View key={index}>
                  <View style={styles.headerSecond}>
                    <Text style={[styles.HeroHeading, { marginHorizontal: 0 }]}>
                      {item.form_group_name}
                    </Text>
                  </View>

                  {item.form_field_data
                    ?.slice(0, 15)
                    .map((field, fieldIndex) => (
                      <View key={fieldIndex} style={styles.row}>
                        <Text
                          wrap={false}
                          style={{
                            fontWeight:
                              field?.form_field_type === "heading"
                                ? "bold"
                                : "normal",
                            fontSize:
                              field?.form_field_type === "heading" ? 14 : 11,
                            flexBasis: "50%",
                            marginRight: 16,
                          }}
                        >
                          {field.form_field_name}
                        </Text>
                        <Text
                          style={{
                            flexBasis: "50%",
                            fontSize: 11,
                            textAlign: "left",
                          }}
                        >
                          {field.form_field_type === "date"
                            ? moment(field.form_field_value).format(
                                "DD/MM/YYYY"
                              )
                            : field.form_field_value}
                        </Text>
                      </View>
                    ))}
                </View>
              )
          )}
        </View>

        <View style={styles.verticalLine} />
        <View style={{ width: "30%", marginLeft: "10px" }}>
          <View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>NAME : </Text>
              <Text>{`${PDetails?.firstName || ""} ${
                PDetails?.lastName || ""
              }`}</Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>AGE : </Text>
              <Text>{PDetails?.age}</Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Sex : </Text>
              <Text>
                {PDetails?.gender === "M"
                  ? "Male"
                  : PDetails?.gender === "F"
                  ? "Female"
                  : "-"}
              </Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Visit Id : </Text>
              <Text>#034-static</Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>D.O.B : </Text>
              <Text>{moment(PDetails?.dob).format("DD/MM/YYYY")}</Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Referral: </Text>
              <Text>{PDetails?.referral?.name || "-"}</Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Patient ID: </Text>
              <Text>{PDetails.patient_id ? PDetails.patient_id : "-"}</Text>
            </View>
          </View>
          <View></View>
        </View>
      </View>

      {/* <View style={styles.HeroHeading}>
        <Text>Vitals(s)</Text>
      </View> */}

      {updatedFormData?.map(
        (item, index) =>
          item.form_group_name === "Demographic Details" && (
            <View key={index}>
              {/* <View style={styles.headerSecond}>
                <Text style={[styles.HeroHeading, { marginHorizontal: 0 }]}>
                  {item.form_group_name}
                </Text>
              </View> */}

              {item.form_field_data?.slice(15).map((field, fieldIndex) => (
                <View key={fieldIndex} style={styles.row}>
                  <Text
                    wrap={false}
                    style={{
                      fontWeight:
                        field?.form_field_type === "heading"
                          ? "bold"
                          : "normal",
                      fontSize: field?.form_field_type === "heading" ? 14 : 11,
                      flexBasis: "50%",
                      marginRight: 16,
                    }}
                  >
                    {field.form_field_name}
                  </Text>
                  <Text
                    style={{
                      flexBasis: "50%",
                      fontSize: 11,
                      textAlign: "left",
                    }}
                  >
                    {field.form_field_type === "date"
                      ? moment(field.form_field_value).format("DD/MM/YYYY")
                      : field.form_field_value}
                  </Text>
                </View>
              ))}
            </View>
          )
      )}

      {/* remaining form data start */}
      {updatedFormData?.map(
        (item, index) =>
        item.form_group_name !== "Demographic Details" &&
        item.form_group_name !== "Demographic Details" && 
        item.form_group_name !== "Area of concern" && 
        item.form_group_name !== "Parental red flags"&& (
            <View key={index}>
              <View style={styles.headerSecond}>
                <Text style={[styles.HeroHeading, { marginHorizontal: 0 }]}>
                  {item.form_group_name}
                </Text>
              </View>

              {item.form_group_name === "Current Concerns"
                ? item.form_field_data?.map((field, fieldIndex) => {
                  if (
                    [
                      "Brushing routine ",
                      "Mealtime routine",
                      "Groming routine",
                      "Bathing routine",
                      "Toileting routine",
                      "House Hold Chores(HHC)",
                    ].includes(field.form_field_name)
                  ) {
                    const tableData = [];
                    let i = fieldIndex + 1;
                    while (
                      i < item.form_field_data.length &&
                      item.form_field_data[i].form_field_type !== "heading"
                    ) {
                      const currentField = item.form_field_data[i];
                      const nextField = item.form_field_data[i + 1];
                      
                      if (currentField.form_field_name !== "Additional Information") {
                        const currentValue = currentField.form_field_value?.trim();
                        const currentName = currentField.form_field_name?.trim();
                        
                        let additionalInfo = "NA";
                        if (nextField) {
                          const nextName = nextField.form_field_name?.replace(/[:-]$/, "")?.trim();
                          const nextValue = nextField.form_field_value?.trim();
                          
                          // Check if the next field is related to the current field
                          if (
                            nextName.startsWith(currentValue) ||
                            nextName.includes(currentValue) ||
                            currentName.includes(nextValue)
                          ) {
                            additionalInfo = nextValue;
                            i++; // Skip the next field as we've used it for additional info
                          }
                        }
                        
                        tableData.push([
                          currentName,
                          currentValue,
                          additionalInfo
                        ]);
                      }
                      i++;
                    }
                      // console.log("tableData", tableData.flat());

                      // if (!extraFeilds.includes(field.form_field_name)) {
                      return (
                        <View key={fieldIndex}>
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              marginBottom: 10,
                              marginLeft: 23,
                            }}
                          >
                            {field.form_field_name}
                          </Text>

                          {/* Header Row */}
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginHorizontal: 20,
                              marginBottom: 5,
                            }}
                          >
                            {/* Header Cells */}
                            <View style={styles.headerBox}>
                              <Text style={styles.headerTextTable}>
                                Achievement
                              </Text>
                            </View>
                            <View style={styles.headerBox}>
                              <Text style={styles.headerTextTable}>
                                Age Of Appropriation
                              </Text>
                            </View>
                            <View style={styles.headerBox}>
                              <Text style={styles.headerTextTable}>
                                Additional Information
                              </Text>
                            </View>
                          </View>

                          {/* Table Rows */}
                          {tableData.map((row, rowIndex) => (
                            <View
                              key={rowIndex}
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginHorizontal: 20,
                                marginBottom: 5,
                              }}
                            >
                              {/* Value Cells */}
                              <View style={styles.box}>
                                <Text style={styles.valueText}>{row[0]}</Text>
                              </View>
                              <View style={styles.box}>
                                <Text style={styles.valueText}>{row[1]}</Text>
                              </View>
                              <View style={styles.box}>
                                <Text style={styles.valueText}>{row[2]}</Text>
                              </View>
                            </View>
                          ))}
                        </View>
                      );
                      // }
                    }
                    // Render non-tabular fields as before
                    const extraFeilds = [
                      "Brushing routine",
                      "Brushing",
                      "Additional Information",
                      "Mealtime routine",
                      "Eating with hand",
                      "Eating with spoon",
                      "Grooming routine",
                      "Dressing",
                      "Undressing",
                      "5 - Performs ADLs independently in schedule -",
                      "Combing hair",
                      "Bathing routine",
                      "Bathing",
                      "5 - Performs ADLs independently in schedule: -",
                      "Toileting routine",
                      "Toileting",
                      "House Hold Chores(HHC)",
                      "Meal time - prep/setting the table/keeping plate back in the kitchen post meal",
                      "2 - Performs HHCs with assistance",
                      "Washing vessels/help in arranging utensils",
                      "1 - Involves and interacts in HHCs:",
                      "Laundry - Drying clothes/ folding clothes/putting clothes in the machine",
                      "5 - Performs HHCs independently in schedule -",
                      "Cleaning- dusting /sweeping/ wiping the table",
                      "3 - Performs HHCs with gestural prompts :",
                      "Keeping shoes in the place",
                      "2 - Performs HHCs with assistance:-",
                    ];

                    return (
                      !extraFeilds.includes(field.form_field_name) && (
                        <View key={fieldIndex}>
                          <View style={styles.pageContainer}>
                            {field?.form_field_type === "checkbox" ? (
                              <View>
                                <Text
                                  style={{
                                    marginBottom: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {field?.form_field_name} :
                                </Text>
                                {convertToArr(field?.form_field_value).map(
                                  (item, index) => (
                                    <View key={index} style={styles.dataDiv}>
                                      <Text
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 11,
                                          marginBottom: 5,
                                          marginLeft: 10,
                                          display: "flex",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {"\u2022"} {item}
                                      </Text>
                                    </View>
                                  )
                                )}
                              </View>
                            ) : (
                              <View wrap={false} style={styles.dataDiv}>
                                <Text
                                  style={{
                                    fontWeight:
                                      field?.form_field_type === "heading"
                                        ? "bold"
                                        : "",
                                    fontSize:
                                      field?.form_field_type === "heading"
                                        ? 14
                                        : 11,
                                    width:
                                      field?.form_field_type === "heading"
                                        ? "100%"
                                        : "50%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginRight: 20,
                                  }}
                                >
                                  {field?.form_field_name}
                                </Text>
                                <Text
                                  style={{
                                    fontWeight: "normal",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    width: "50%",
                                    marginLeft: 10,
                                    textAlign: "left",
                                  }}
                                >
                                  {field?.form_field_type === "date" ? (
                                    moment(field?.form_field_value).format(
                                      "DD/MM/YYYY"
                                    )
                                  ) : field?.form_field_name === "Reports" ? (
                                    <Link src={field?.form_field_value}>
                                      check report
                                    </Link>
                                  ) : (
                                    field?.form_field_value
                                  )}
                                </Text>
                              </View>
                            )}
                          </View>
                        </View>
                      )
                    );
                  })
                : item.form_field_data?.map((field, fieldIndex) => {
                    if (
                      [
                        "Gross Motor Skills (Age of Achievement)",
                        "Fine Motor Skills (Age of Achievement)",
                        "Social Communication (Age of Achievement)",
                      ].includes(field.form_field_name)
                    ) {
                      const tableData = [];
                      let i = fieldIndex + 1;
                      while (
                        i < item.form_field_data.length &&
                        item.form_field_data[i].form_field_type !== "heading"
                      ) {
                        if (i + 1 < item.form_field_data.length) {
                          if (
                            item.form_field_data[i].form_field_name !==
                            "Additional Information"
                          ) {
                            tableData.push([
                              item.form_field_data[i].form_field_name,
                              `${item.form_field_data[i].form_field_value} (${
                                item.form_field_data[i + 1].form_field_value
                              })`,
                              "Test",
                            ]);
                            i += 2;
                          } else {
                            i++;
                          }
                        } else {
                          i++;
                        }
                      }
                      // console.log("tableData", tableData.flat());

                      // if (!extraFeilds.includes(field.form_field_name)) {
                      return (
                        <View key={fieldIndex}>
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              marginBottom: 10,
                              marginLeft: 23,
                            }}
                          >
                            {field.form_field_name}
                          </Text>

                          {/* Header Row */}
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginHorizontal: 20,
                              marginBottom: 5,
                            }}
                          >
                            {/* Header Cells */}
                            <View style={styles.headerBox}>
                              <Text style={styles.headerTextTable}>
                                Achievement
                              </Text>
                            </View>
                            <View style={styles.headerBox}>
                              <Text style={styles.headerTextTable}>
                                Age Of Appropriation
                              </Text>
                            </View>
                            <View style={styles.headerBox}>
                              <Text style={styles.headerTextTable}>
                                Additional Information
                              </Text>
                            </View>
                          </View>

                          {/* Table Rows */}
                          {tableData.map((row, rowIndex) => (
                            <View
                              key={rowIndex}
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginHorizontal: 20,
                                marginBottom: 5,
                              }}
                            >
                              {/* Value Cells */}
                              <View style={styles.box}>
                                <Text style={styles.valueText}>{row[0]}</Text>
                              </View>
                              <View style={styles.box}>
                                <Text style={styles.valueText}>{row[1]}</Text>
                              </View>
                              <View style={styles.box}>
                                <Text style={styles.valueText}>{row[2]}</Text>
                              </View>
                            </View>
                          ))}
                        </View>
                      );
                      // }
                    }
                    // Render non-tabular fields as before
                    const extraFeilds = [
                      "Gross Motor Skills (Age of Achievement)",
                      "1. Head Holding:(3-4 Months)",
                      "Age Appropriation",
                      "2. Independent  Sitting (6-8 Months)",
                      "Age Appropriation",
                      "3. Independent Standing(10-12 MONTHS)",
                      "Age Appropriation",
                      "4. Independent Walking (12-14 Months )",
                      "Age Appropriation",
                      "5. Staircase climbing with support (21-24 months )",
                      "Age Appropriation",
                      "6.Jumps with both feet on the floor (24-30 months)",
                      "Age Appropriation",
                      "7.Jumps down from the bottom step of stairs, both feet together (30-36 months )",
                      "Age Appropriation",
                      "8. One leg standing ( 42-48 months )",
                      "Age Appropriation",
                      "9. Skipping forward after demonstration ( 5-6 years)",
                      "Age Appropriation",
                      "Additional Information",
                      "Fine Motor Skills (Age of Achievement)",
                      "1. Bidextrous Grasp (3-4 Months)",
                      "Age Appropriation",
                      "2. Transfer of Objects(6-7 Months )",
                      "Age Appropriation",
                      "3. Pincer Grasp (9-11 MONTHS)",
                      "Age Appropriation",
                      "4.  Makes Tower of 5-6 cubes (18-21 months)",
                      "Age Appropriation",
                      "5. Can unbutton (36-42 months )",
                      "Age of Appropriation",
                      "6. Can do buttoning (42-48 months )",
                      "Age Appropriation",
                      "7. Holding the pencil with a tripod grasp (45-54 months )",
                      "Age Appropriation",
                      "Social Communication (Age of Achievement)",
                      "1. Social Smile (1.5 -2 Months)",
                      "Age of Appropriation",
                      "2. Cooing ( 4-5 Months)",
                      "Age of Appropriation",
                      "3. Babbling ( 6-7 Months)",
                      "Age of Appropriation",
                      "4. Meaningful Words (12-14 Months)",
                      "Age of Appropriation",
                      "5. Complete Sentences (24-30 months )",
                      "Age of Appropriation",
                      'Provisional Diagnosis ',
                      "Area of concern",
                      'Area of Concerns'
                    ];
                    return (
                      !extraFeilds.includes(field.form_field_name) && (
                        <View key={fieldIndex}>
                          <View style={styles.pageContainer}>
                            {field?.form_field_type === "checkbox" ? (
                              <View>
                                <Text
                                  style={{
                                    marginBottom: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {field?.form_field_name} :
                                </Text>
                                {convertToArr(field?.form_field_value).map(
                                  (item, index) => (
                                    <View key={index} style={styles.dataDiv}>
                                      <Text
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 11,
                                          marginBottom: 5,
                                          marginLeft: 10,
                                          display: "flex",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {"\u2022"} {item}
                                      </Text>
                                    </View>
                                  )
                                )}
                              </View>
                            ) : (
                              <View wrap={false} style={styles.dataDiv}>
                                <Text
                                  style={{
                                    fontWeight:
                                      field?.form_field_type === "heading"
                                        ? "bold"
                                        : "",
                                    fontSize:
                                      field?.form_field_type === "heading"
                                        ? 14
                                        : 11,
                                    width:
                                      field?.form_field_type === "heading"
                                        ? "100%"
                                        : "50%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginRight: 20,
                                  }}
                                >
                                  {field?.form_field_name}
                                </Text>
                                <Text
                                  style={{
                                    fontWeight: "normal",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    width: "50%",
                                    marginLeft: 10,
                                    textAlign: "left",
                                  }}
                                >
                                  {field?.form_field_type === "date" ? (
                                    moment(field?.form_field_value).format(
                                      "DD/MM/YYYY"
                                    )
                                  ) : field?.form_field_name === "Reports" ? (
                                    <Link src={field?.form_field_value}>
                                      check report
                                    </Link>
                                  ) : (
                                    field?.form_field_value
                                  )}
                                </Text>
                              </View>
                            )}
                          </View>
                        </View>
                      )
                    );
                  })}
            </View>
          )
      )}
      {/* remaining form data end */}

      {/* Body ends */}

      {/* Golden rules */}
      <View
        style={{
          flexDirection: "column",
          display: "flex",
          marginTop: "15",
          marginHorizontal: "10",
        }}
      >
        <Text
          style={{
            marginBottom: "7",
          }}
        >
          {res?.golden_rules_data !== null ? "Golden Rules" : ""}
        </Text>
        <View
          style={{
            flexDirection: "column",
            display: "flex",
            gap: 5,
            justifyContent: "start",
          }}
        >
          {res?.golden_rules_data &&
            convertToArr(
              removeLeadingComma(res?.golden_rules_data?.golden_rules)
            )?.map((item, index) => {
              return (
                <Text
                  key={index}
                  style={{
                    fontSize: "11",
                    marginBottom: "10",
                    marginLeft: "10",
                    textAlign: "left",
                    lineHeight: 1,
                  }}
                >
                  {"\u2022"} {capitalizeFirstLetter(item)}
                </Text>
              );
            })}
        </View>
      </View>

      {res?.recommendation_comment?.comments && (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "8",
            marginHorizontal: "10",
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "16",
              marginRight: "10",
            }}
          >
            Suggestion :{" "}
          </Text>
          <Text
            style={{
              fontWeight: "normal",
              fontSize: "11",
              marginRight: "10",
            }}
          >
            {res?.recommendation_comment?.comments}
          </Text>
        </View>
      )}
      <View>
        <View
          style={{
            flexDirection: "column",
            display: "flex",
            marginHorizontal: "20",
          }}
        >
          <View wrap={false}>
            {res.recommedations.length > 0 && (
              <View
                style={{
                  flexDirection: "column",
                  border: "1px solid gray",
                  marginTop: "10",
                  borderRadius: "5",
                  padding: "10",
                }}
              >
                <View
                  style={{
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "16",
                    }}
                  >
                    Recommendation
                  </Text>
                </View>

                {res.recommedations.length > 0 && (
                  <View>
                    {res?.recommedations?.map((item, index) => {
                      return (
                        <View
                          key={index}
                          style={{
                            boarder: "1px solid gray",
                          }}
                        >
                          <Text
                            style={{
                              marginTop: "10",
                              fontSize: "11",
                            }}
                          >
                            Name: {item.program_name}
                          </Text>
                          <View
                            style={{
                              marginTop: "4",
                            }}
                          >
                            {item.sessions.map((item, index) => (
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  gap: "3",
                                }}
                              >
                                <Text
                                  style={{
                                    marginTop: "4",
                                    fontSize: "11",
                                    width: "40%",
                                  }}
                                >
                                  Department Name: {item.department_name}
                                </Text>
                                {item.department_name === "E-nable" ? (
                                  <Text
                                    style={{
                                      marginTop: "4",
                                      fontSize: "11",
                                      width: "50%",
                                    }}
                                  >
                                    Total Sessions: 9 E-nable calls, 3 OPD's, 1
                                    Parental webinar
                                  </Text>
                                ) : (
                                  <Text
                                    style={{
                                      marginTop: "4",
                                      fontSize: "11",
                                      width: "50%",
                                    }}
                                  >
                                    Total Session: {item.total_sessions}
                                  </Text>
                                )}
                              </View>
                            ))}
                          </View>
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            )}
          </View>
          <View wrap={false}>
            {res.prescription_data.length > 0 && (
              <View
                style={{
                  flexDirection: "column",
                  marginTop: "15",
                  padding: "10",
                }}
              >
                <View
                  style={{
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "16",
                    }}
                  >
                    Prescription
                  </Text>
                </View>
              </View>
            )}
            {res.prescription_data.length > 0 && (
              <TableDocument tableheaderArray={tableheaderArray} res={res} />
            )}
          </View>
        </View>
      </View>
      {res.recommedations.length > 0 ||
      res?.recommendation_comment?.comments ? (
        <View
          style={{
            flexDirection: "column",
            display: "flex",
            marginTop: "15",
            marginHorizontal: "12",
          }}
        >
          <Text
            style={{
              marginBottom: "7",
              fontSize: "11",
            }}
          >
            1. DP consultation once a month
          </Text>
          <Text
            style={{
              marginBottom: "7",
              fontSize: "11",
            }}
          >
            2. CH feedback once a month
          </Text>
          <Text
            style={{
              marginBottom: "7",
              fontSize: "11",
            }}
          >
            3. Online Monthly Parent Webinar
          </Text>
          <Text
            style={{
              marginBottom: "7",
              fontSize: "11",
            }}
          >
            4. Quarterly Progress Report
          </Text>
        </View>
      ) : null}

      {/* bottom part */}
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />
    </PDFDocument>
  );
};

// --------------------------------------------------------

const tableheaderArray = [
  "Medicine Name",
  "Strength",
  "Formulation",
  "Route of Administration",
  "Frequency",
  "Time",
  "Duration",
  "Start Date",
  "Instructions",
];
const TableDocument = ({ tableheaderArray, res }) => {
  const createTableHeader = () => {
    return (
      <View wrap={false} style={tableRowStyle} fixed>
        {res.prescription_data.length > 0 &&
          tableheaderArray.map((item, index) => {
            return (
              <View style={tableColHeaderStyle} key={index}>
                <Text style={tableCellHeaderStyle}>{item}</Text>
              </View>
            );
          })}
      </View>
    );
  };

  const CreateTableRow = ({ item }) => {
    return (
      <View style={tableRowStyle}>
        <View style={firstTableColStyle}>
          <Text style={tableCellStyle}>
            {addWhitespace(item.medicine_name)}
          </Text>
        </View>

        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.strength}</Text>
        </View>
        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.formulation}</Text>
        </View>

        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.route_of_administration}</Text>
        </View>

        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.frequency}</Text>
        </View>

        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.time}</Text>
        </View>
        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.duration}</Text>
        </View>
        <View style={tableColStyle}>
          <Text style={tableCellStyle}>
            {moment(item.start_date).format("DD/MM/YYYY")}
          </Text>
        </View>
        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.instruction}</Text>
        </View>
      </View>
    );
  };

  return (
    <View style={tableStyle}>
      {createTableHeader()}
      {/* {createTableRow()} */}

      {res.prescription_data.length > 0 &&
        res?.prescription_data?.map((item, index) => {
          return <CreateTableRow key={index} item={item} />;
        })}
    </View>
  );
};

const tableStyle = {
  display: "table",
  width: "auto",
};

const tableRowStyle = {
  flexDirection: "row",
};

const tableColHeaderStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#bdbdbd",
};

const firstTableColStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderTopWidth: 0,
};

const tableColStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
};

const tableCellHeaderStyle = {
  textAlign: "center",
  margin: 4,
  fontSize: 11,
  fontWeight: "bold",
  height: "auto",
};

function addWhitespace(word) {
  if (word.length > 10) {
    let middleIndex = Math.floor(word.length / 2);
    if (word.length % 2 === 0) {
      // if word length is even, add whitespace at the middle
      return word.slice(0, middleIndex) + " - " + word.slice(middleIndex);
    } else {
      // if word length is odd, add whitespace after the middle
      return (
        word.slice(0, middleIndex + 1) + " - " + word.slice(middleIndex + 1)
      );
    }
  } else {
    return word;
  }
}

const tableCellStyle = {
  textAlign: "center",
  margin: 5,
  fontSize: 10,
  whiteSpace: "break-word",
  wordWrap: "pre-wrap",
};

Font.register({
  family: "Myriad Pro",
  fonts: [
    { src: MyriadProRegular, fontWeight: "normal" },
    { src: MyriadProBold, fontWeight: "bold" },
    { src: MyriadProSemibold, fontWeight: "semibold" },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Myriad Pro",
    fontSize: 11,
    paddingTop: 200,
    paddingBottom: 100,
    paddingHorizontal: 5,
    fontWeight: "normal",
  },
  title: {
    flexDirection: "row",
    fontWeight: "bold",
  },
  verticalLine: {
    width: 1,
    height: "100%",
    backgroundColor: "black",
    marginLeft: 10,
    marginRight: 10,
    opacity: 0.2,
  },
  contentHeader: {
    fontSize: 11,
    marginLeft: 10,
    paddingHorizontal: 10,
  },
  docHeader: {
    position: "absolute",
    top: 50,
    left: 30,
  },

  name: {
    fontSize: 24,
    fontWeight: "bold",
  },
  role: {
    fontSize: 16,
    color: "#F2727D",
  },
  qualifications: {
    fontSize: 12,
  },
  center: {
    fontSize: 14,
    fontWeight: "bold",
  },
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: 800,
  },
  headerImage: {
    width: "100%",
    height: "100%",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 800,
  },
  footerImage: {
    width: "100%",
    height: "100%",
  },
  content: {
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  headerContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 35,
  },
  headerText: {
    fontSize: 10,
  },
  footerText: {
    fontSize: 8,
    color: "grey",
  },
  colorBar: {
    width: "100%",
    height: 5,
    flexDirection: "row",
  },
  colorBarSection: {
    width: "20%",
    height: 5,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: -1,
  },
  watermarkImage: {
    width: 400,
    height: 400,
    opacity: 0.1,
    transform: "rotate(-20deg)",
  },
  HeroHeading: {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    marginHorizontal: 20,
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
    marginTop: 10,
    marginBottom: 10,
    paddingVertical: 5,
  },
  logo: {
    position: "absolute",
    right: 35,
    top: 10,
    width: 150,
    height: 60,
  },
  titleHead: {
    position: "relative",
    height: 80,
    marginBottom: 10,
  },
  headerDiv: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    marginRight: 10,
  },
  pageContainer: {
    marginHorizontal: 20,
    marginBottom: 7,
  },
  bellowHeader: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  headerSecond: {
    fontSize: 16,
    textAlign: "left",
    fontWeight: "bold",
    marginHorizontal: 20,
    marginBottom: 10,
    marginTop: 20,
  },
  dataDiv: {
    flexDirection: "row",
    marginRight: 8,
    marginBottom: 2,
    fontSize: 11,
  },
  bold: {
    fontWeight: "bold",
    fontSize: 14,
    marginBottom: 10,
  },
  text: {
    fontSize: 11,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 8,
    // borderBottomWidth: 1,
    // borderBottomColor: "#ccc",
    marginHorizontal: 18,
  },
  headerTextTable: {
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
  },
  valueText: {
    fontSize: 12,
    color: "#333333",
    textAlign: "center",
    padding: 10,
  },
  box: {
    flex: 1,
    backgroundColor: "#f7f7f7",
    margin: 4,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  headerBox: {
    flex: 1,
    backgroundColor: "#3CC3DD",
    margin: 4,
    paddingVertical: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ReportPdf;
